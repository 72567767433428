<template>
  <div class="mt-0.5">
    <label>
      <input
        data-lpignore="true"
        type="checkbox"
        class="shop-checkbox"
        :checked="selected"
        @change="changeHandler"
      />

      <span class="font-alt font-bold text-black text-sm ml-2">
        <span class="ml-2">{{ name }}</span>
      </span>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'ShopCheckbox',
    props: {
      item: {
        type: Object,
        required: true
      },

      selected: {
        type: Boolean,
        default: false
      }
    },
    emits: ['checkbox'],
    computed: {
      name() {
        return this.item?.label || ''
      },

      value() {
        return this.item?.value || null
      }
    },

    methods: {
      changeHandler(event) {
        this.$emit('checkbox', { item: this.item, event })
      }
    }
  }
</script>

<style lang="postcss" scoped>
  .shop-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    border: 1px solid #7c93a5;
  }
  .shop-checkbox + *::before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    background: #f2f4f6;
    border-radius: 3px;
    border: 1px solid #7c93a5;
    height: 20px;
    width: 20px;
    overflow: auto;
  }

  .shop-checkbox:checked + *::before {
    background-color: #3fb9c5;
    border: 3px solid #fff;
    outline: 1px solid #7c93a5;
  }
</style>
